import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToken } from "../../flux/actions/tokenIndex";
import { setUser } from "../../flux/actions/userIndex";
import api from "../../services/api";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from "@material-ui/core";
import { checarPermissao, validateTokenUser, getUser, getToken, formatStringUniversalDateToLocal } from '../../utils/utils.js'

import Radio from '@material-ui/core/Radio';
import Loader from "react-loader-spinner";
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));


function HierarquiaVendas(props) {
    const [pedidos, setPedidos] = useState('')
    const [wait, setWait] = useState(false)
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    const [indexR, setIndexR] = useState(0)
    const [msg, setMsg] = useState('')
    const [permitido, setPermitido] = useState(false)
    const [massAprov, setMassAprov] = useState([])

    const [validadeAproval, setValidadeAproval] = useState([])
    const [statePedidos, setStatePedidos] = useState(0)
    const [salvando, setSalvando] = useState(false)
    const [alterContrato, setAlterContrato] = useState([])

    useEffect(() => {
        async function fetchMyAPI() {
            await validateTokenUser(props)
            setPermitido(await checarPermissao('hierarquiaVendas', props))

            const response_aguardando = await api.get("/PedidosAprovacao", { params: { userId: getUser(), token: getToken() } });
            setPedidos(response_aguardando.data)

            var a = new Array(response_aguardando.data.length).fill('a');
            var b = new Array(response_aguardando.data.length).fill('a');
            setMassAprov(a)
            setValidadeAproval(b)
        }

        fetchMyAPI();
    }, []);

    async function inserePedido(pedido, aprov) {
        setWait(true)

        // caso o usuário tenha selecionado a checkbox do pedido, ele vai estar no alterContrato, o que significa que ele é um contrato alternativo
        let tipoContrato = 0
        if (alterContrato.includes(pedido)) {
            tipoContrato = 1
        }

        const response_aprova = await api.get("/AprovaPedido", { params: { pedido, aprov, msg, tipoContrato, userId: getUser(), token: getToken() } });

        if (response_aprova.data === 'NOK') {
            alert('Erro ao inserir pedido')
        } else if (response_aprova.data == 'OK') {
            alert('Pedido inserido com sucesso!')
            window.location.reload();
        } else if (response_aprova.data == 'OKN') {
            alert('Pedido reprovado com sucesso!')
            window.location.reload();
        }
        setWait(false)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenInfo = () => {
        setOpenInfo(true);
    };

    const handleCloseInfo = () => {
        setOpenInfo(false);
    };

    const alterState = (index, val) => {
        let aux = massAprov.slice()
        aux[index] = val
        setMassAprov(aux)
    }

    async function inserePedidosMassa() {
        let count = 0
        setSalvando(true)
        for (var i = 0; i < massAprov.length; i++) {
            if (massAprov[i] == 'b') {
                count += 1
                setStatePedidos(count)
                let pedido = pedidos[i].id

                let tipoContrato = 0
                if (alterContrato.includes(pedido)) {
                    tipoContrato = 1
                }

                const response_aprova = await api.get("/AprovaPedido", { params: { pedido, 'aprov': '1', 'msg': '', tipoContrato, userId: getUser(), token: getToken() } });

                if ((response_aprova.data == 'OK') || (response_aprova.data == 'OKN')) {
                    let aux = validadeAproval
                    aux[i] = true
                    setValidadeAproval(aux)
                } else {
                    let aux = validadeAproval
                    aux[i] = false
                    setValidadeAproval(aux)
                }

            }
            else if (massAprov[i] == 'c') {
                count += 1
                setStatePedidos(count)
                let pedido = pedidos[i].id
                const response_aprova = await api.get("/AprovaPedido", { params: { pedido, 'aprov': '-1', 'msg': '', userId: getUser(), token: getToken() } });
                if (response_aprova.data == 'OKN') {
                    let aux = validadeAproval
                    aux[i] = true
                    setValidadeAproval(aux)
                } else {
                    let aux = validadeAproval
                    aux[i] = false
                    setValidadeAproval(aux)
                }

            }
        }
        setSalvando(false)
        handleClickOpenInfo()
    }

    // adiciona o número do pedido em um array para indicar se a Checkbox de contrato dele foi marcada ou não
    const mudaContrato = (numPedido) => {
        let aux = [...alterContrato]

        if (aux.includes(numPedido)) {
            let index = aux.indexOf(numPedido)
            aux.splice(index, 1)
        } else {
            aux.push(numPedido)

        }
        setAlterContrato(aux)
    }

    return (
        permitido === true ?
            <div>
                <p>Pedidos pendentes</p>
                <GridContainer>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '20%', justifyContent: 'space-around' }}>
                        <p>Ignorar</p>
                        <p>Aprovar</p>
                        <p>Reprovar</p>
                    </div>
                    {Object.keys(pedidos).map((index) =>
                        pedidos[index].addSAP === 2 ?
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 10 }} key={index}>

                                <div style={{ display: 'flex', flexDirection: 'row', width: '25%', justifyContent: 'space-around' }}> <Radio
                                    checked={massAprov[index] === 'a'}
                                    onChange={() => alterState(index, 'a')}
                                    value="a123"
                                    name={"gg" + index.toString()}
                                    color="default"
                                    label='123'
                                />
                                    <Radio
                                        checked={massAprov[index] === 'b'}
                                        onChange={() => alterState(index, 'b')}
                                        value="b"
                                        name={"gg" + index.toString()}
                                        color="default"
                                    />
                                    <Radio
                                        checked={massAprov[index] === 'c'}
                                        onChange={() => alterState(index, 'c')}
                                        value="c"
                                        name={"gg" + index.toString()}
                                        color="default"
                                    />  </div>
                                <Accordion style={{ width: '100%' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id={index}>

                                        <Typography className={classes.heading}>Cliente:{pedidos[index].argsVenda.cliente}</Typography>
                                        {pedidos[index].addSAP === 2 ? <Typography className={classes.secondaryHeading}> Pedido Pendente de Aprovação</Typography> :
                                            pedidos[index].addSAP === 1
                                                ? <Typography style={{ color: '#12824C' }}> Pedido Aprovado </Typography> :
                                                <Typography style={{ color: '#DE3163' }}> Pedido Reprovado </Typography>
                                        }

                                        <Typography className={classes.heading}>
                                            {pedidos[index].argsVenda.desmembramento === 'true' ?
                                                <div style={{ alignContent: 'center', textAlign: 'center', position: 'absolute', right: '10%' }}>Desmembramento do pedido {pedidos[index].argsVenda.pedidoMae}</div>
                                                : null}
                                        </Typography>
                                    </AccordionSummary>

                                    <div style={{ margin: 10, border: '1px solid rgba(0, 0, 0, .4)', padding: 10, borderRadius: 4 }}>
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography>
                                                -Vendedor : {pedidos[index].argsVenda.vendedor}
                                            </Typography>
                                            <Typography>
                                                -Supervisor : {pedidos[index].argsVenda.supervisor}
                                            </Typography>
                                            <Typography>
                                                -Cliente : {pedidos[index].argsVenda.cliente}
                                            </Typography>
                                            <Typography>
                                                -Observações : {pedidos[index].argsVenda.observ}
                                            </Typography>
                                            <Typography>
                                                -Produto : {pedidos[index].argsVenda.nomeProduto}
                                            </Typography>
                                            <Typography>
                                                -Quantidade: {pedidos[index].argsVenda.qtde}
                                            </Typography>
                                            <Typography>
                                                -Preço : {pedidos[index].argsVenda.uni}{pedidos[index].argsVenda.moeda}
                                            </Typography>
                                            <Typography>
                                                -Embalagem : {pedidos[index].argsVenda.emb}
                                            </Typography>
                                            <Typography>
                                                -Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd1}
                                            </Typography>
                                            <Typography>
                                                -Origem : {pedidos[index].argsVenda.selOrigem}
                                            </Typography>
                                            <Typography>
                                                -Destino: {pedidos[index].argsVenda.selDestino}
                                            </Typography>
                                        </AccordionDetails>
                                        {pedidos[index].argsVenda.nomeProduto2 !== '' ?
                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                                <Typography>
                                                    -Produto : {pedidos[index].argsVenda.nomeProduto2}
                                                </Typography>
                                                <Typography>
                                                    -Quantidade: {pedidos[index].argsVenda.qtde2}
                                                </Typography>
                                                <Typography>
                                                    -Preço : {pedidos[index].argsVenda.uni2} {pedidos[index].argsVenda.moeda}
                                                </Typography>
                                                <Typography>
                                                    -Embalagem : {pedidos[index].argsVenda.emb2}
                                                </Typography>
                                                <Typography>
                                                    -Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd2}
                                                </Typography>
                                            </AccordionDetails> : null}
                                        {pedidos[index].argsVenda.nomeProduto3 !== '' ?
                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                                <Typography>
                                                    -Produto : {pedidos[index].argsVenda.nomeProduto3}
                                                </Typography>
                                                <Typography>
                                                    -Quantidade: {pedidos[index].argsVenda.qtde3}
                                                </Typography>
                                                <Typography>
                                                    -Preço : {pedidos[index].argsVenda.uni3}{pedidos[index].argsVenda.moeda}
                                                </Typography>
                                                <Typography>
                                                    -Embalagem : {pedidos[index].argsVenda.emb3}
                                                </Typography>
                                                <Typography>
                                                    -Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd3}
                                                </Typography>
                                            </AccordionDetails> : null}
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>

                                                <Typography>
                                                    -Inicio Entrega : {formatStringUniversalDateToLocal(pedidos[index].argsVenda.iniDate)}
                                                </Typography>
                                                <Typography>
                                                    -Fim Entrega: {formatStringUniversalDateToLocal(pedidos[index].argsVenda.endDate)}
                                                </Typography>
                                                <Typography>
                                                    -Tipo Frete : {pedidos[index].argsVenda.frete}
                                                </Typography>
                                                <Typography>
                                                    -Valor do Frete: {pedidos[index].argsVenda.valFreteVendido}
                                                </Typography>

                                            </AccordionDetails>
                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>

                                                <Typography>
                                                    -Cidade Entrega : {pedidos[index].argsVenda.cidadeEntrega}
                                                </Typography>
                                                <Typography>
                                                    -Estado Entrega : {pedidos[index].argsVenda.estadoEntrega}
                                                </Typography>
                                                {pedidos[index].argsVenda['insProd[]'] === undefined ?
                                                    <Typography>
                                                        -Inscrição Estadual: {pedidos[index].argsVenda['insProdVal']}
                                                    </Typography> :
                                                    <Typography>
                                                        -Inscrição Estadual: {JSON.parse(pedidos[index].argsVenda['insProd[]']).TaxId1}
                                                    </Typography>}

                                                <Typography>
                                                    -Endereço Entrega : {pedidos[index].argsVenda.endEntrega}
                                                </Typography>
                                                <Typography>
                                                    -Local Entrega : {pedidos[index].argsVenda.local}
                                                </Typography>
                                                <Typography>
                                                    -Valor Frete: {pedidos[index].argsVenda.valFreteVendido}
                                                </Typography>
                                                <Typography>
                                                    -Roteiro: {pedidos[index].argsVenda.roteiro}
                                                </Typography>
                                            </AccordionDetails>
                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>

                                                <Typography>
                                                    -Filial : {pedidos[index].argsVenda.filial}
                                                </Typography>
                                                <Typography>
                                                    -Forma de Pagamento : {pedidos[index].argsVenda.forma}
                                                </Typography>
                                                {pedidos[index].argsVenda.forma === 'À Vista' ? null :
                                                    <div>
                                                        <Typography>
                                                            -Parcela 1: {pedidos[index].argsVenda.par1}
                                                        </Typography>
                                                        <Typography>
                                                            -Parcela 2: {pedidos[index].argsVenda.par2}
                                                        </Typography>
                                                        <Typography>
                                                            -Parcela 3: {pedidos[index].argsVenda.par3}
                                                        </Typography>
                                                        <Typography>
                                                            -Parcela 4: {pedidos[index].argsVenda.par4}
                                                        </Typography>
                                                    </div>
                                                }

                                                <Typography>
                                                    -Vencimento: {formatStringUniversalDateToLocal(pedidos[index].argsVenda.dataCond)}
                                                </Typography>
                                                <Typography>
                                                    -Moeda: {pedidos[index].argsVenda.moeda} {pedidos[index].argsVenda.moeda !== 'R$' ? ': ' + pedidos[index].argsVenda.docRate : ''}
                                                </Typography>
                                            </AccordionDetails>
                                        </div>

                                        <div>
                                            <Typography>Contrato Alternativo: <Checkbox onChange={() => mudaContrato(pedidos[index].id)} /></Typography>
                                            <div>

                                                {(pedidos[index]['argsVenda'].fiadores !== undefined) & (pedidos[index]['argsVenda'].fiadores !== '') ?
                                                    <>
                                                        <Typography>Fiadores</Typography>
                                                        {JSON.parse(pedidos[index]['argsVenda'].fiadores).map((fiador, index) =>
                                                            <div key={index} style={{ border: '1px solid rgba(0, 0, 0, .2)', margin: 5, padding: 10 }}>
                                                                <Typography>Nome: {fiador.nome}</Typography>
                                                                <Typography>  CPF: {fiador.cpf}</Typography>
                                                                <Typography>Endereço: {fiador.rua}, {fiador.numeroCasa}, {fiador.bairro}, {fiador.numeroCasa}, {fiador.cep}</Typography>
                                                            </div>)}
                                                    </> : null}
                                            </div>
                                        </div>

                                        {pedidos[index].addSAP === 2 ? <div>
                                            <Button disabled={wait} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => inserePedido(pedidos[index].id, 1)}>   Aprovar Pedido e inserir no SAP</Button>
                                            <Button disabled={wait} style={{ backgroundColor: '#DE3163', color: '#FFFFFF' }} onClick={() => (handleClickOpen(), setIndexR(pedidos[index].id))}>   Reprovar Pedido</Button></div> : <div />}
                                    </div>

                                </Accordion>
                            </div> : null)}
                    <div>

                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Reprovar Pedido</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Descrição do motivo da reprovação da venda:
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    fullWidth
                                    onChange={(e) => setMsg(e.target.value)}
                                    multiline
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={() => (inserePedido(indexR, -1), handleClose)} color="primary">
                                    Reprovar Pedido
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </GridContainer>
                <Button disabled={salvando || massAprov.filter(obj => obj != 'a').length == 0} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => inserePedidosMassa()}> Enviar operações</Button>
                {(salvando) ? <div> <Loader
                    type="Circles"
                    color="#00BFFF"
                    height={20}
                    width={20}
                    timeout={2000000} // 3 secs
                /> Realizando operação com pedido {statePedidos} de {massAprov.filter(obj => obj != 'a').length} </div> : <></>}
                <div >

                    <Dialog fullWidth={true}
                        maxWidth={false} open={openInfo} onClose={handleCloseInfo} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Informações sobre aprovação dos pedidos selecionados</DialogTitle>
                        <DialogContent>
                            {Object.keys(pedidos).map((index, key) =>
                                massAprov[index] !== 'a' ?
                                    <div key={key}>
                                        <p>
                                            {pedidos[index].argsVenda.cliente} -{">"}
                                            {validadeAproval[index] === true ? 'Pedido ' + (massAprov[index] == 'b' ? 'Aprovado' : 'Reprovado') + ' com sucesso' : 'erro ao inserir pedido, entre em contato com o time de desenvolvimento, id sysgen ->' + pedidos[index].id.toString()}</p>
                                    </div> : null)}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => window.location.reload()} color="primary">
                                OK
                            </Button>

                        </DialogActions>
                    </Dialog>
                </div>
                {Object.keys(pedidos).map((index) =>
                    pedidos[index].addSAP !== 2 ?
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 10 }} key={index}>
                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={index}

                                >
                                    <Typography className={classes.heading}>Cliente:{pedidos[index].argsVenda.cliente}</Typography>
                                    {pedidos[index].addSAP === 2 ? <Typography className={classes.secondaryHeading}> Pedido Pendente de Aprovação</Typography> :
                                        pedidos[index].addSAP === 1
                                            ? <Typography style={{ color: '#12824C' }}> Pedido Aprovado </Typography> :
                                            <Typography style={{ color: '#DE3163' }}> Pedido Reprovado </Typography>
                                    }
                                    <Typography className={classes.heading}>
                                        {pedidos[index].argsVenda.desmembramento === 'true' ?
                                            <div style={{ alignContent: 'center', textAlign: 'center', position: 'absolute', right: '10%' }}>Desmembramento do pedido {pedidos[index].argsVenda.pedidoMae}</div>
                                            : null}
                                    </Typography>
                                </AccordionSummary>
                                <div style={{ border: "1px solid", margin: 10 }}>
                                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>
                                            -Vendedor : {pedidos[index].argsVenda.vendedor}
                                        </Typography>
                                        <Typography>
                                            -Supervisor : {pedidos[index].argsVenda.supervisor}
                                        </Typography>
                                        <Typography>
                                            -Cliente : {pedidos[index].argsVenda.cliente}
                                        </Typography>
                                        <Typography>
                                            -Observações : {pedidos[index].argsVenda.observ}
                                        </Typography>
                                        <Typography>
                                            -Produto : {pedidos[index].argsVenda.nomeProduto}
                                        </Typography>
                                        <Typography>
                                            -Quantidade: {pedidos[index].argsVenda.qtde}
                                        </Typography>
                                        <Typography>
                                            -Preço : {pedidos[index].argsVenda.uni}{pedidos[index].argsVenda.moeda}
                                        </Typography>
                                        <Typography>
                                            -Embalagem : {pedidos[index].argsVenda.emb}
                                        </Typography>
                                        <Typography>
                                            -Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd1}
                                        </Typography>
                                        <Typography>
                                            -Origem : {pedidos[index].argsVenda.selOrigem}
                                        </Typography>
                                        <Typography>
                                            -Destino: {pedidos[index].argsVenda.selDestino}
                                        </Typography>
                                    </AccordionDetails>
                                    {pedidos[index].argsVenda.nomeProduto2 !== '' ?
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                            <Typography>
                                                -Produto : {pedidos[index].argsVenda.nomeProduto2}
                                            </Typography>
                                            <Typography>
                                                -Quantidade: {pedidos[index].argsVenda.qtde2}
                                            </Typography>
                                            <Typography>
                                                -Preço : {pedidos[index].argsVenda.uni2} {pedidos[index].argsVenda.moeda}
                                            </Typography>
                                            <Typography>
                                                -Embalagem : {pedidos[index].argsVenda.emb2}
                                            </Typography>
                                            <Typography>
                                                -Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd2}
                                            </Typography>
                                        </AccordionDetails> : null}
                                    {pedidos[index].argsVenda.nomeProduto3 !== '' ?
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>

                                            <Typography>
                                                -Produto : {pedidos[index].argsVenda.nomeProduto3}
                                            </Typography>
                                            <Typography>
                                                -Quantidade: {pedidos[index].argsVenda.qtde3}
                                            </Typography>
                                            <Typography>
                                                -Preço : {pedidos[index].argsVenda.uni3}{pedidos[index].argsVenda.moeda}
                                            </Typography>
                                            <Typography>
                                                -Embalagem : {pedidos[index].argsVenda.emb3}
                                            </Typography>
                                            <Typography>
                                                -Total {pedidos[index].argsVenda.moeda}: {pedidos[index].argsVenda.totProd3}
                                            </Typography>
                                        </AccordionDetails> : null}
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>

                                            <Typography>
                                                -Inicio Entrega : {formatStringUniversalDateToLocal(pedidos[index].argsVenda.iniDate)}
                                            </Typography>
                                            <Typography>
                                                -Fim Entrega: {formatStringUniversalDateToLocal(pedidos[index].argsVenda.endDate)}
                                            </Typography>
                                            <Typography>
                                                -Tipo Frete : {pedidos[index].argsVenda.frete}
                                            </Typography>

                                            {(pedidos[index]['argsVenda'].fiadores !== undefined) & (pedidos[index]['argsVenda'].fiadores !== '') ?
                                                <>
                                                    <Typography>Fiadores</Typography>
                                                    {JSON.parse(pedidos[index]['argsVenda'].fiadores).map((fiador, index) =>
                                                        fiador !== null ?
                                                            <div key={index} style={{ border: '1px solid rgba(0, 0, 0, .2)', margin: 5, padding: 10 }}>
                                                                <Typography>Nome: {fiador.nome}</Typography>
                                                                <Typography>  CPF: {fiador.cpf}</Typography>
                                                                <Typography>Endereço: {fiador.rua}, {fiador.numeroCasa}, {fiador.bairro}, {fiador.numeroCasa}, {fiador.cep}</Typography>
                                                            </div> : null)}
                                                </> : null}

                                        </AccordionDetails>
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>

                                            <Typography>
                                                -Cidade Entrega : {pedidos[index].argsVenda.cidadeEntrega}
                                            </Typography>
                                            <Typography>
                                                -Estado Entrega : {pedidos[index].argsVenda.estadoEntrega}
                                            </Typography>
                                            {pedidos[index].argsVenda['insProd[]'] === undefined ?
                                                <Typography>
                                                    -Inscrição Estadual: {pedidos[index].argsVenda['insProdVal']}
                                                </Typography> :
                                                <Typography>
                                                    -Inscrição Estadual: {JSON.parse(pedidos[index].argsVenda['insProd[]']).TaxId1}
                                                </Typography>}

                                            <Typography>
                                                -Endereço Entrega : {pedidos[index].argsVenda.endEntrega}
                                            </Typography>
                                            <Typography>
                                                -Local Entrega : {pedidos[index].argsVenda.local}
                                            </Typography>
                                            <Typography>
                                                -Valor Frete: {pedidos[index].argsVenda.valFreteVendido}
                                            </Typography>
                                            <Typography>
                                                -Roteiro: {pedidos[index].argsVenda.roteiro}
                                            </Typography>
                                        </AccordionDetails>
                                        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: '33%' }}>

                                            <Typography>
                                                -Filial : {pedidos[index].argsVenda.filial}
                                            </Typography>
                                            <Typography>
                                                -Forma de Pagamento : {pedidos[index].argsVenda.forma}
                                            </Typography>
                                            {pedidos[index].argsVenda.forma === 'À Vista' ? null :
                                                <div>
                                                    <Typography>
                                                        -Parcela 1: {pedidos[index].argsVenda.par1}
                                                    </Typography>
                                                    <Typography>
                                                        -Parcela 2: {pedidos[index].argsVenda.par2}
                                                    </Typography>
                                                    <Typography>
                                                        -Parcela 3: {pedidos[index].argsVenda.par3}
                                                    </Typography>
                                                    <Typography>
                                                        -Parcela 4: {pedidos[index].argsVenda.par4}
                                                    </Typography>
                                                </div>
                                            }

                                            <Typography>
                                                -Vencimento: {formatStringUniversalDateToLocal(pedidos[index].argsVenda.dataCond)}
                                            </Typography>
                                            <Typography>
                                                -Moeda: {pedidos[index].argsVenda.moeda} {pedidos[index].argsVenda.moeda !== 'R$' ? ': ' + pedidos[index].argsVenda.docRate : ''}
                                            </Typography>

                                        </AccordionDetails>
                                    </div>



                                    {pedidos[index].addSAP === 2 ? <div>
                                        <Button disabled={wait} style={{ backgroundColor: '#12824C', color: '#FFFFFF' }} onClick={() => inserePedido(pedidos[index].id, 1)}>   Aprovar Pedido e inserir no SAP</Button>
                                        <Button disabled={wait} style={{ backgroundColor: '#DE3163', color: '#FFFFFF' }} onClick={() => (handleClickOpen(), setIndexR(pedidos[index].id))}>   Reprovar Pedido</Button></div> : <div />}
                                </div>

                            </Accordion>
                        </div> : null)}
            </div> : <></>
    );
}
function mapStateToProps(state) {
    return {
        token: state.token,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setToken: setToken,
            setUser: setUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(HierarquiaVendas);
